<template>
    <div class="findNo" >

            <img src="../assets/find/1.png" alt="">
    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },
    created() {

    },
    mounted() {
    //   setTimeout(() => {
    //         console.log(111)
    //         this.$router.push('/')
    //     }, 5000);
    this.find()
    },
    methods: {
        find(){
            if(this.$router.push == '/pano'){
            setTimeout(() => {
                            console.log(111)
                            this.$router.push('/')
                        }, 5000);
            }
            
        }
    }
};
</script>

<style scoped>
.findNo{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100000000000;
}
.findNo img{
    z-index: 1000000;
}
</style>
